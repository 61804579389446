<template>
    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget :headerText="sale_text" :ButtonBack="true">
                    <div class="col-md-12" style="border-bottom: 1px solid #444242;"
                         v-for="(item, index) in transaction.transaction_detail">
                        <div class="row">
                            <div class="col">
                                <label class="labelBigAnalytic">Producto</label>
                                <p class="labelMediumAnalytic">{{item.product_station.product.name}}</p>
                            </div>
                            <div class="col">
                                <label class="labelBigAnalytic">Cantidad</label>
                                <p class="labelMediumAnalytic">
                                    {{item.cant}}</p>
                            </div>
                            <div class="col">
                                <label class="labelBigAnalytic">Monto</label>
                                <p class="labelMediumAnalytic">
                                    ${{item.total}}</p>
                            </div>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
        </div>
    </div>
</template>

<script>

    import {SpringSpinner} from "epic-spinners";
    import {mapState, mapActions} from "vuex";

    export default {
        name: "StationSaleDetail",
        components: {
            SpringSpinner,
        },
        data() {
            return {
                sale: this.$route.params.idproducttrasaction,
                sale_text: 'Detalles de la  venta ' + this.$route.params.idproducttrasaction,
                transaction: null
            };
        },
        computed: {
            ...mapState({})
        },
        mounted() {
            var id = this.$route.params.idproducttrasaction;
            if (id !== null) {
                this.loadSalesByStationDetails(id)
                    .then(data => {
                        this.transaction = data
                    })
                    .catch(data => {
                        this.addToastMessage({
                            text: "Ocurrio un error",
                            type: "success"
                        });
                    });
            } else {
                this.addToastMessage({
                    text: "Ocurrio un error",
                    type: "success"
                });
            }
        },
        methods: {
            ...mapActions(["loadSalesByStationDetails", "addToastMessage"]),
            back() {
                // this.$router.go(-1);
                this.$router.push("/");
            }
        }
    };
</script>

<style lang="scss">
    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }

    .labelBigAnalytic {
        font-size: 30px;
    }

    .labelMediumAnalytic {
        font-size: 15px;
        font-weight: bold;
    }
</style>
